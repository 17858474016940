<div class="container">
  <div class="columns is-centered">
    <div class="box column is-one-third">
      <h1 class="title has-text-centered">Password Generator</h1>

      <div class="field">
        <label>Length</label>
        <input class="input" (input)="onChangeLength($event.target.value)" />
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input (change)="onChangeUseLetters()" type="checkbox" />
            Use Letters
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input (change)="onChangeUseNumbers()" type="checkbox" />
            Use Numbers
          </label>
        </div>
      </div>

      <div class="field">
        <div class="control">
          <label class="checkbox">
            <input (change)="onChangeUseSymbols()" type="checkbox" />
            Use Symbols
          </label>
        </div>
      </div>

      <button [disabled]="
          !(length && (useLetters || useSymbols || useNumbers))
        " (click)="onButtonClick()" class="button is-link is-fullwidth">
        Generate!
      </button>

      <div class="box" *ngIf="password">
        <label>Your Password</label>
        <input class="input" [value]="password" />
      </div>
    </div>
  </div>
</div>